import resaleAgreements from "./resale-agreements"
import timeline from "./timeline"
import types from "./types"
import revisions from "./revisions"
import enedisContractSubscriptionTypes from "./enedis-contract-subscription-types"

export default {
  "resale-agreements": resaleAgreements,
  revisions,
  timeline,
  types,
  "enedis-contract-subscription-types": enedisContractSubscriptionTypes,
  mayor: {
    contactCard: {
      website: "Site web",
      plans: "Plans",
      phone: "Telephone du standard",
      email: "Email du standard",
      "urban-planning-phone": "Telephone du service d'urbanisme",
      "urban-planning-email": "Email du service d'urbanisme",
    },
  },
}
